<template>
    <div>
        <b-row>
            <b-col cols="10">
                    <h4 v-if="checkFlag">Please configure short question.</h4>
                    <h4 v-else>Please select excel sheet for bulk question saving</h4>
                </b-col>
                <b-col cols="2">
                    <toggle-button :width="75" :height="28" v-model="checkFlag" :value="false" :color="{checked: '#104e94', unchecked: '#e9ecef', disabled: '#CCCCCC'}" :speed="500" :sync="true" :labels="{checked: 'enter', unchecked: 'load'}"/>
                </b-col>
            <b-form v-if="checkFlag" class="col-12"  @submit="onSubmit" @reset="onReset">
                <b-row>
                    <b-col class="col-6">
                        <label for="title">Enter Title</label>
                        <b-form-input v-model="answerShortQuestion.title" placeholder="Enter Question Title"></b-form-input>
                    </b-col>
                    <b-col cols="6">
                            <label for="mock_ind">Select Question Type</label>
                            <b-form-select v-model="answerShortQuestion.mock_ind" :options="options"></b-form-select>
                    </b-col>
                </b-row>
                <b-col cols="12">
                    <div class="form-group">
                        <label for="textarea">Enter Transcript</label>
                        <textarea v-model="answerShortQuestion.transcript" class="form-control" id="textarea" rows="10"></textarea>
                    </div>
                </b-col>
                <b-col class="col-12">
                    <label for="title">Select Audio</label>
                    <b-form-file
                        v-model="answerShortQuestion.short_recording"
                        :state="Boolean(answerShortQuestion.short_recording)"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        accept=".mp3,audio/*"
                        @change="onChange"
                        ></b-form-file>
                </b-col>
                <div class="col-6 displayInline" align="right">
                    <b-button type="submit" variant="primary" :disabled="allFilled != true">{{buttonText}}</b-button>
                </div>
                <div class="col-6 displayInline" align="left">
                    <b-button type="reset" variant="outline-danger">Reset</b-button>
                </div>
            </b-form>
        </b-row>
        <b-row v-if="checkFlag" class="mt-4 ml-2 mr-2">
            <b-col cols="12">
                <b-alert
                :show="dismissCountDown"
                dismissible
                variant="outline-success"
                @dismissed="dismissCountDown=0"
                
                >
                Record saved successfully, dismiss in {{ dismissCountDown }} seconds...
                </b-alert>
            </b-col>
        </b-row>
        <b-row v-if="checkFlag" class="mt-4 ml-2 mr-2">
            <b-col cols="12">
                <b-table
                    id="multipleChoiceSingle"
                    :items="allQuestions"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :fields="fields"
                    sort-icon-left
                    small
                    outlined
                    responsive
                    >
                    <template #cell(short_question_id)="data">
                    <!-- `data.value` is the value after formatted by the Formatter -->
                    <b-button variant="outline-warning" @click="deleteRecord(data.value)">
                        <b-icon icon="trash-fill"></b-icon>
                    </b-button>
                    <!-- <a href="#">{{data.value}}</a> -->
                </template>
                <template #cell(transcript)="data">
                    <!-- `data.value` is the value after formatted by the Formatter -->
                    <read-more class="readmoreDiv" more-str="read more" :text="data.value" link="#" less-str="read less" :max-chars="100"></read-more>
                    <!-- <p class="transcript">{{data.value}}</p> -->
                </template>
                </b-table>
            </b-col>
            <b-col cols="12">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="multipleChoiceSingle"
                    ></b-pagination>
            </b-col>
        </b-row>
        <div v-if="!checkFlag" class="row mt-4 ml-2 mr-2">
            <div class="col-12">
                
                <div class="form-group">
                    <label for="excelFile">Select Excel file</label>
                    <b-form-file
                        id="excelFile"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        accept=".xlsx"
                        @change="onFileChange"></b-form-file>
                </div>
            </div>
            <div class="col-12 text-center">
                <b-button :disabled="!fileLoad" @click="readFile()" class="mr-4">Read File</b-button>
                <b-button :disabled="!readFileFlag" variant="primary" @click="saveRecordExcel(1)">{{saveRecord}}</b-button>
            </div>
        </div>
        <div v-if="!checkFlag" class="row mt-4 ml-2 mr-2">
            <div class="col-12">
            <ul id="console-textarea" >
                <li v-for="(item,index) in consoleLog" :key="index">
                    {{item}}
                </li>
            </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions,mapGetters } from 'vuex'
import readXlsxFile from 'read-excel-file'
// import blobToBase64 from 'blob-to-base64'
export default {
    data(){
        return{
            buttonText:'Save',
            dismissSecs: 5,
            dismissCountDown: 0,
            allFilled: false,
            loadedFile:null,
            excelFile:null,
            fileLoad:false,
            checkFlag:true,
            readFileFlag:false,
            rowsLoadedArray:null,
            saveRecord:'Save Record',
            consoleLog:['Log Info.'],
            answerShortQuestion:{
                title:'',
                short_recording:null,
                slot_time:'02:00',
                url:'',
                transcript:'',
                mock_ind:'0'
            },
            allQuestions:null,
            perPage: 8,
            currentPage: 1,
            fields: [
            {
                key: 'short_question_id',
                label:'Delete',
                sortable: false
            },
            {
                key: 'title',
                label:'Title',
                sortable: false
            },
            {
                key: 'transcript',
                label:'Transcript',
                sortable: false
            }
            ],
            options: [
            { value: '0', text: 'Please select an option' },
            { value: 'F', text: 'General' },
            { value: 'T', text: 'Mock Test' }
            ]
        }
    },
    methods:{
        ...mapActions({
            saveAnswer: 'speakmodule/saveAnswer',
            saveQuestion:'speakmodule/saveQuestion',
            questionservice: 'speakmodule/fetchQuestion',
            fileUpload:'speakmodule/uploadFile',
            deleteQuestion:'speakmodule/deleteQuestion'
        }),
        ...mapGetters({
            answerResponse: 'speakmodule/AnswerResponse',
            getQuestionList: 'speakmodule/questions'
        }),
        onFileChange(e) {
            const file = e.target.files[0];
            this.excelFile=file
            this.fileLoad =true
            this.readFileFlag=false
            this.consoleLog.push('Selected file loaded.')
            // this.loadedFile = e.target.files
            // this.fileUrl = URL.createObjectURL(file);
        },
        readFile(){
            if(this.excelFile!=null){
            readXlsxFile(this.excelFile).then((rows) => {
                            //console.log(rows)
                            this.consoleLog.push('Total records retrieve from excel sheet ' +rows.length +'.')
                            this.fileLoad =false
                            this.readFileFlag=true
                            this.rowsLoadedArray=rows
                        })
            }
        },
        saveRecordExcel(index){
            if(this.rowsLoadedArray==null)
            {
                return this.$alert('No record found')
            }
            else{
                let today = new Date().toLocaleDateString()
                var item=this.rowsLoadedArray[index]
                //console.log(item)
                this.answerShortQuestion={
                title:item[1],
                short_recording:item[3],
                slot_time:today,
                url:'',
                transcript:item[2],
                mock_ind:'F',
                new_ind:item[4],
                predicted_ind:item[5]?'Y':'N',
                exam_ind:item[6]?'Y':'N',
            }
            var findRecord=null
            // All entries are in sequence so no need to find or update
            
            // var findRecord=this.allQuestions.filter((item)=>{
            //         return item.transcript.trim().toLowerCase()==this.answerShortQuestion.transcript.trim().toLowerCase()
            //     })[0]
            //     if(findRecord!=null){
            //         this.answerShortQuestion.url='configshortquestion/UpdateBulkShortQuestion'
            //         this.answerShortQuestion.short_question_id=findRecord.short_question_id
            //     }
            //     else {
                    this.answerShortQuestion.url='configshortquestion/InsertBulkShortQuestion'
               // }
               // Current entries were till 711 so I start from from onward.
               //if(index>=712)
               //{
                   this.saveQuestion(this.answerShortQuestion).then(()=>{
                        var res = this.answerResponse()
                        var id=0
                        var dte=new Date().toISOString().slice(0, 19).replace('T', ' ');
                        this.consoleLog.push(res.message)
                        if(findRecord!=null){
                            id=findRecord.short_question_id
                        }
                        else{
                            id=res.data.insertId
                            this.consoleLog.push(this.answerShortQuestion.title)
                            this.consoleLog.push(this.answerShortQuestion.short_recording)
                        }
                        const param={
                            tableName:'tblShortQuestion',
                            idName:'short_question_id', 
                            id:id,
                            predicted_ind:this.answerShortQuestion.predicted_ind,
                            predicted_date:dte,
                            exam_ind:this.answerShortQuestion.exam_ind,
                            title:this.answerShortQuestion.title,
                            exam_date:dte,
                            type:'single',
                            url:'updatequestion/status',
                        }
                        //console.log(param)
                        this.saveQuestion(param).then(()=>{
                            var resStatus = this.answerResponse()
                            this.consoleLog.push(resStatus.message)
                            if(index<this.rowsLoadedArray.length-1){
                            this.saveRecordExcel(index+1)
                            }
                        })
                    });
               //}
            //    else{
            //        if(this.allQuestions[index]!=null){
            //            var dte=new Date().toISOString().slice(0, 19).replace('T', ' ');
            //         const param={
            //                 tableName:'tblShortQuestion',
            //                 idName:'short_question_id', 
            //                 id:this.allQuestions[index].short_question_id,
            //                 predicted_ind:this.answerShortQuestion.predicted_ind,
            //                 predicted_date:dte,
            //                 exam_ind:this.answerShortQuestion.exam_ind,
            //                 title:this.answerShortQuestion.title,
            //                 exam_date:dte,
            //                 type:'single',
            //                 url:'updatequestion/status',
            //             }
                        
            //             //console.log(param)
            //             this.saveQuestion(param).then(()=>{
            //                 var resStatus = this.answerResponse()
            //                 this.consoleLog.push(resStatus.message)
            //                 if(index<this.rowsLoadedArray.length-1){
            //                 this.saveRecordExcel(index+1)
            //                 }
            //             })
            //        }
            //    }
                
                // //console.log(this.readaloud)
                // if(index<this.rowsLoadedArray.length-1){
                //     this.saveRecordExcel(index+1)
                // }
                
            }
        },
        onReset(evt) {
            evt.preventDefault()
            this.resetFrom()
        },
        resetFrom(){
            this.allFilled= false,
            this.loadedFile = null
            this.answerShortQuestion={
                title:'',
                short_recording:null,
                slot_time:'',
                url:'',
                transcript:'',
                mock_ind:'0'
            }
        },
        onChange(e) {
            this.loadedFile = e.target.files
        },
        onSubmit(evt) {
            evt.preventDefault()
            //////console.log(JSON.stringify(this.readaloud))
           // var tempurl = URL.createObjectURL(this.answerShortQuestion.short_recording);
            this.answerShortQuestion.url = 'configshortquestion/createshortquestion'
            var vm=this
            if(!this.matchString(this.answerShortQuestion.transcript, this.answerShortQuestion.title)) 
                return vm.$alert(
                            "Similar question is already saved!",
                            "Warning",
                            "warning",
                            {
                                confirmButtonText: "OK"
                            })
                //alert('Similar question is already saved!');
               
                 var data={
                fileName:'answer_questions--',
                files:this.loadedFile
                }
            //console.log(data)
            //vm.answerShortQuestion.short_recording = 'Nothing'
                vm.saveQuestion(vm.answerShortQuestion).then(()=>{
                                ////console.log(vm.answerResponse())
                                vm.userAnswerResponse = vm.answerResponse().data
                                vm.answerShortQuestion.short_question_id = vm.userAnswerResponse.insertId
                                data.fileName+= vm.userAnswerResponse.insertId
                                vm.fileUpload(data)
                                vm.allQuestions.push( vm.answerShortQuestion)
                                vm.$parent.getQuestionTypes();
                                //////console.log(vm.userAnswerResponse.insertId)

                                vm.resetFrom();
                                //////console.log(vm.answerShortQuestion)
                                vm.dismissCountDown = vm.dismissSecs
                                //alert('Data has been saved successfully.');
                                
                            });
            // fetch(tempurl).then(function(response) {
            //     if(response.ok) {
            //         return response.blob();
            //     }
            //     else{
            //         throw new Error('Network response was not ok.');
            //     }
            //     }).then(function(blob) {
            //         blobToBase64(blob, function (error, base64) {
            //             if (!error) {
            //                 //////console.log(vm)
            //                 vm.answerShortQuestion.short_recording = base64
            //                 vm.saveQuestion(vm.answerShortQuestion).then(()=>{
            //                     ////console.log(vm.answerResponse())
            //                     vm.userAnswerResponse = vm.answerResponse().data
            //                     vm.answerShortQuestion.short_question_id = vm.userAnswerResponse.insertId
            //                     vm.allQuestions.push( vm.answerShortQuestion)
            //                     vm.$parent.getQuestionTypes();
            //                     //////console.log(vm.userAnswerResponse.insertId)

            //                     vm.resetFrom();
            //                     //////console.log(vm.answerShortQuestion)
            //                     vm.dismissCountDown = vm.dismissSecs
            //                     //alert('Data has been saved successfully.');
                                
            //                 });
            //                 return base64;
            //             }
            //             else{
            //                 ////console.log(error)
            //             }
            //         });
                // }).catch(function(error) {
                // //console.log('There has been a problem with your fetch operation: ', error.message);
                // });
        },
        deleteRecord(id){
            var data={
                url:'configshortquestion/deleteShortQuestion/id='+id
            }
            var vm = this
             this.deleteQuestion(data).then(()=>{
                vm.allQuestions = this.allQuestions.filter(function (item) {
                return item.short_question_id != id;
                })
                ////console.log(vm.allQuestions)
                vm.$parent.getQuestionTypes();
            });
            ////console.log(id)
        },
        matchString(transcript,title){
            var existQuestion = this.allQuestions.filter(function (item) {
                return item.transcript.toLowerCase().trim() === transcript.toLowerCase().trim() | 
                item.title.toLowerCase().trim() === title.toLowerCase().trim();
                })[0];
            return (existQuestion !=null | existQuestion != undefined)?  false: true;
        }
    },
    watch:{
        answerShortQuestion: {
            deep: true,
            handler () {
                this.allFilled = false
                if(this.answerShortQuestion.title.trim() !== '' & this.answerShortQuestion.slot_time.trim() !== '' & (this.answerShortQuestion.short_recording !== null))
                {
                    this.allFilled = true
                }
            }
        }
    },
    computed: {
      rows() {
          if(this.allQuestions == null){
              return 0
          }
          else{
              return this.allQuestions.length
          }
      }
    },
    beforeMount(){
        this.allQuestions = null
        this.questionservice('configshortquestion/getAllShortQuestionsForGrid').then(()=>{
            this.allQuestions = this.getQuestionList().data
            ////console.log(this.allQuestions)
        })
    },
}
</script>
<style scoped>
label
{
    margin-top: 10px;
text-transform: capitalize;
font-weight: 500;
}
textarea {
    height: 40vh;
    width: 100%;
    border: 1px solid #ccc;
    resize: none;
}
.vue-js-switch{
    margin-top: 0px!important;
}
</style>